import _stream from "stream";
import _encode from "./encode";
import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;

/**
 * Module dependencies.
 */
var Stream = _stream.Writable;
var encode = _encode;
/**
 * Expose parser.
 */

exports = Parser;
/**
 * Initialize parser.
 *
 * @param {Options} [opts]
 * @api public
 */

function Parser(opts) {
  Stream.call(this || _global, opts);
  (this || _global).state = "message";
  (this || _global)._lenbuf = new Buffer(4);
}
/**
 * Inherit from `Stream.prototype`.
 */


Parser.prototype.__proto__ = Stream.prototype;
/**
 * Write implementation.
 */

Parser.prototype._write = function (chunk, encoding, fn) {
  for (var i = 0; i < chunk.length; i++) {
    switch ((this || _global).state) {
      case "message":
        var meta = chunk[i];
        (this || _global).version = meta >> 4;
        (this || _global).argv = meta & 15;
        (this || _global).state = "arglen";
        (this || _global)._bufs = [new Buffer([meta])];
        (this || _global)._nargs = 0;
        (this || _global)._leni = 0;
        break;

      case "arglen":
        (this || _global)._lenbuf[(this || _global)._leni++] = chunk[i]; // done

        if (4 == (this || _global)._leni) {
          (this || _global)._arglen = (this || _global)._lenbuf.readUInt32BE(0);
          var buf = new Buffer(4);
          buf[0] = (this || _global)._lenbuf[0];
          buf[1] = (this || _global)._lenbuf[1];
          buf[2] = (this || _global)._lenbuf[2];
          buf[3] = (this || _global)._lenbuf[3];

          (this || _global)._bufs.push(buf);

          (this || _global)._argcur = 0;
          (this || _global).state = "arg";
        }

        break;

      case "arg":
        // bytes remaining in the argument
        var rem = (this || _global)._arglen - (this || _global)._argcur; // consume the chunk we need to complete
        // the argument, or the remainder of the
        // chunk if it's not mixed-boundary

        var pos = Math.min(rem + i, chunk.length); // slice arg chunk

        var part = chunk.slice(i, pos);

        (this || _global)._bufs.push(part); // check if we have the complete arg


        (this || _global)._argcur += pos - i;
        var done = (this || _global)._argcur == (this || _global)._arglen;
        i = pos - 1;
        if (done) (this || _global)._nargs++; // no more args

        if ((this || _global)._nargs == (this || _global).argv) {
          (this || _global).state = "message";
          this.emit("data", Buffer.concat((this || _global)._bufs));
          break;
        }

        if (done) {
          (this || _global).state = "arglen";
          (this || _global)._leni = 0;
        }

        break;
    }
  }

  fn();
};

export default exports;