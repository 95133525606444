import _stream from "./lib/stream";
import _encode from "./lib/encode";
import _decode from "./lib/decode";
var exports = {};
exports.Stream = _stream;
exports.encode = _encode;
exports.decode = _decode;
export default exports;
export const Stream = exports.Stream,
      encode = exports.encode,
      decode = exports.decode;